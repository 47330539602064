<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="md" :title="$t('skill.title_level_edit')" footer-class="footerClass" 
      no-close-on-backdrop  content-class="skill-level-modal shadow"
      @hidden="hidden">

      <b-alert variant="danger" dismissible v-model="state.errorShow">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ $t('error.attention_required') }} 
      </b-alert>

      <div class="container pl-0">
        <b-row>
          <b-col cols="12" class="pr-0">
            <b-form-group :label="$t('skill.field.skill')" label-for="skillName">
              <b-input-group>
                <b-form-input type="text" :placeholder="$t('skill.select_skill')" readonly class="form-label"
                    @click="canList() ? skillSelectorOpen : ''"
                    v-model="skillName"
                    :data-vv-as="$t('skill.field.skill')"
                    data-vv-name="skillName"
                    data-vv-delay="500"
                    v-validate="{ required: true }"/> 
                <b-input-group-append v-if='canList()'>
                  <b-button @click="skillSelectorOpen"><span>Select</span></b-button>
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showSkillNameError }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('skillName') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['name'] != null">
            <b-col v-for="(field, index) in customFieldMap['name']" :key="'name'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="customData[field.name]" :componentId="componentId" :field="field"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <template v-if="customFieldMap['default'] != null">
            <b-col v-for="(field, index) in customFieldMap['default']" :key="index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="customData[field.name]" :componentId="componentId" :field="field"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col cols="12" class="pr-0">
            <b-form-group :label="$t('skill.field.level')" label-for="skillLevel">
              <multiselect v-model="skillLevel" class="custom-dropdown-options enable-option-icon"
                :max-height="300"
                :options="levelOpts.map(i => i.value)"
                :placeholder="$t('skill.select_level')"
                :searchable="false" 
                :allow-empty="false"
                :showLabels="false">
                <template slot="option" slot-scope="props">
                  <font-awesome-icon class="selected-option-icon" v-if="skillLevel == props.option" :icon="['far', 'check']" />
                  <span class="option__title">{{ props.option }}</span>
                </template>
              </multiselect>
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showSkillLevelError }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('skillLevel') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['level'] != null">
            <b-col v-for="(field, index) in customFieldMap['level']" :key="'level'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="customData[field.name]" :componentId="componentId" :field="field"></CustomField>
              </b-form-group>
            </b-col>
          </template>

        </b-row>
      </div>
      
              
      

      <template v-slot:modal-footer="{}">
        <b-form-checkbox v-if="canApplyAll" class="apply-to-all" v-model="applyToAll">{{ $t('apply_to_all') }}</b-form-checkbox>
        <b-button size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <!-- skill selector -->
    <GenericSelectorModalForAdmin v-if="state.skillSelectorShow"
      :show.sync="state.skillSelectorShow" 
      :entityService="skillUtil"
      entity="SKILL"
      :preselected="id"
      nonAdmin
      singleSelection
      @ok="skillSelectorOk"
    />
  </div>
</template>

<script>
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { viewProfileService } from '@/services'
import { getCustomFieldInfo, customFieldValidate } from '@/helpers/custom-fields';
import { getAppendAfterObjectWithTopDownRelationship } from '@/components/modal/script/field';
import { skillUtil } from '@/views/management/script/skill';
import Multiselect from 'vue-multiselect';
export default {
  name: 'SkillLevelModal',
  components: {
    CustomField: () => import('@/components/CustomField.vue'),
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin'),
    Multiselect
  },
  props: {
    uuId: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    level: {
      type: String,
      default: null
    },
    show: {
      type: Boolean,
      required: true
    },
    canApplyAll: {
      type: Boolean,
      default: false
    },
    edgeName: {
      type: String,
      default: null
    },
    cData: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    return {
      permissionName: 'SKILL',
      state: {
        modalShow: false,
        errorShow: false,
        skillSelectorShow: false
      },
      id: null,
      skillName: null,
      skillLevel: null,
      skillSelector: [],
      oldId: null,
      applyToAll: false,
      levelOpts: [],
      
      customFields: [],
      customData: {},
      customFieldMap: {}
    }
  },
  created() {
    this.userId = null
    this.newToProfile = null
    this.profileSettings = null
    this.lastChosenSkillLevel = null
    this.skillUtil = skillUtil
    this.updateShow(this.show);
    this.updateUuId(this.uuId);
    this.updateName(this.name);
    this.customData = { ...this.cData };
    
    if (this.show) {
      this.init()
    }
  },
  mounted() {
    this.userId = this.$store.state.authentication.user.uuId;
    this.processWhenShowModal(this.show);
  },
  beforeDestroy() {
    this.newToProfile = null
    this.userId = null
    this.lastChosenSkillLevel = null
    this.profileSettings = null
    this.skillUtil = null
  },
  watch: {
    show(newValue) {
      this.processWhenShowModal(newValue);
    },
    uuId(newValue) {
      this.updateUuId(newValue);
    },
    name(newValue) {
      this.updateName(newValue);
    },
    level(newValue) {
      this.updateLevel(newValue);
    },
    cData(newValue) {
      this.customData = { ...newValue };
    }
  },
  computed: {
    componentId() {
      return `SKILL_LEVEL_FORM_${this.id}`;
    },
    customFieldsFiltered() {
      return this.customFields;
    },
    showSkillNameError() {
      return fieldValidateUtil.hasError(this.errors, 'skillName');
    },
    showSkillLevelError() {
      return fieldValidateUtil.hasError(this.errors, 'skillLevel');
    }
  },
  methods: {
    async processWhenShowModal(newValue) {
      if (newValue) {
        await getCustomFieldInfo(this, 'SKILL_LINK', this.edgeName);
        this.$validator.resume();
        if (this.customFields.length == 0) {
          this.customFieldMap = {};
        } else {
          this.customFieldMap = getAppendAfterObjectWithTopDownRelationship(this.customFields);
        }
        this.init()
        this.customData = JSON.parse(JSON.stringify(this.cData));
      }
      this.updateShow(newValue);
      this.applyToAll = false;
    },
    updateShow(newValue) {
      this.state.modalShow = newValue;
    },
    updateUuId(newValue) {
      this.id = newValue;
    },
    updateName(newValue) {
      this.skillName = newValue;
    },
    updateLevel(newValue) {
      let val = newValue
      if (this.levelOpts.length == 0) {
        val = null
      } else if (val != null && this.levelOpts.find(i => i.value === val) == null) {
        val = null
      }

      if (val == null && this.lastChosenSkillLevel != null && this.levelOpts.find(i => i.value === this.lastChosenSkillLevel) != null) {
        val = this.lastChosenSkillLevel
      }

      if (val == null && this.levelOpts.length > 0) {
        val = this.levelOpts[0].value
      }
      this.skillLevel = val;
    },
    ok() {
      if (this.skillLevel == null) {
        this.errors.add({
          field: `skillLevel`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('skill.field.level')])
        });
      }

      const customFields = this.customFieldsFiltered;
      for (const field of customFields) {
        if (!customFieldValidate(field, this.customData[field.name])) {
          field.showError = true;
          return;  
        }
      }
      
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.state.errorShow = false;
          this.profileSettings.lastChosenSkillLevel = this.skillLevel
          this.updateViewProfile()
          this.$emit('ok', { id: this.id, name: this.skillName, level: this.skillLevel, oldId: this.oldId, applyToAll: this.applyToAll, customFields: this.customFields, customData: this.customData });
          this.$emit('update:show', false);
          
        } else {
          this.state.errorShow = true;
        }
      });
    },
    cancel() {
      this.$validator.pause();
      this.$emit('cancel');
      this.$emit('update:show', false);
    },
    hidden() {
      this.$validator.pause();
      this.$emit('update:show', false);
    },
    skillSelectorOpen() {
      this.skillSelector.splice(0, this.skillSelector.length, this.id);
      this.state.skillSelectorShow = true;
    },
    skillSelectorOk({ details }) {
      if (details == null || details.length == 0) {
        return;
      }
      const selected = details[0];
      this.oldId = this.uuId;
      this.id = selected.uuId;
      this.skillName = selected.name;
    },
    async loadViewProfile(callback=null) {
      await this.$store.dispatch('data/viewProfileList', this.userId).then((value) => {  
        const profileData = value
        if (profileData.length === 0) {
          this.createViewProfile()
        }
        else {
          this.profileSettings = profileData[0]
          if (typeof this.profileSettings.lastChosenSkillLevel != 'undefined') {
            this.lastChosenSkillLevel = this.profileSettings.lastChosenSkillLevel
          } else {
            this.newToProfile = true
          }
        }
        if (typeof callback === 'function') {
          callback()
        }
      })
      .catch((e) => {
        console.error(e) // eslint-disable-line no-console
      })
    },
    createViewProfile() {
      viewProfileService.create([this.profileSettings],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase]
        this.profileSettings.uuId = data[0].uuId
        this.newToProfile = true
      })
      .catch((e) => {
        console.error(e) // eslint-disable-line no-console
      })
    },
    updateViewProfile() {
      viewProfileService.update([this.profileSettings], this.userId)
      .catch((e) => {
        console.error(e) // eslint-disable-line no-console
      })
    },
    init() {
      this.$store.dispatch('data/enumList')
      .then(enumResp => {
        if (enumResp != null) {
          if (enumResp.jobCase != null && enumResp[enumResp.jobCase] != null) {
            const propertyList = enumResp[enumResp.jobCase]
            const obj = propertyList.SkillLevelEnum
            const codes = Object.keys(obj)
            const list = []
            for (const c of codes) {
              if (obj[c] < 0) {
                continue
              }
              list.push({ value: c, text: c, num: obj[c] })
            }
            this.levelOpts.splice(0, this.levelOpts.length, ...list)
            this.loadViewProfile(() => {
              this.updateLevel(this.level)
            })
          }
        }
      })
      .catch(e => {
        console.error(e) //eslint-disable-line no-console
      })
    },
  }

}
</script>

<style lang="scss">
.skill-level-modal .apply-to-all {
  position: absolute;
  left: 15px;
}
</style>